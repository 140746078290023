.pricing-plan--shadow__recommended {
  animation: 1s infinite alternate ease-in-out shadow_pulse;
}

.pricing-plan--border__default {
  background: linear-gradient(90.05deg, #6b7280 0.06%, #202224 102.47%);
}

@keyframes shadow_pulse {
  from {
    box-shadow: 0 0 10px 0 rgba(200, 255, 98, 0.24);
  }
  to {
    box-shadow: 0 0 30px 0 rgba(200, 255, 98, 0.24);
  }
}

.pricing-banner__trial {
  background-image: url("/src/assets/icons/pricing-trial-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
