.hero--background {
  @apply container absolute left-1/2 top-1/2 -z-10 aspect-square -translate-x-1/2 -translate-y-1/2 lg:-translate-y-[60.5%];
}

.hero--border_1 {
  @apply absolute left-1/2 top-1/2 aspect-square w-full -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-gray-400/10;
}

.hero--border_2 {
  width: 85%;
  @apply absolute left-1/2 top-1/2 aspect-square -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-gray-400/10;
}

.hero--border_3 {
  width: 70%;
  @apply absolute left-1/2 top-1/2 aspect-square -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-gray-400/10;
}

.hero--blur {
  z-index: 10;
  width: 70%;
  filter: blur(130px);
  @apply absolute left-1/2 top-1/2 z-10 aspect-square -translate-x-1/2 -translate-y-1/2 rounded-full bg-main-green;
}
