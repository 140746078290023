.button {
  @apply w-fit rounded-full border px-4 py-2.5 text-center text-sm lg:px-6 lg:text-base;
}

.button_primary {
  @apply border-main-green bg-main-green text-main-black;
}

.button_primary:hover {
  @apply border-opacity-80 bg-opacity-80;
}

.button_dark {
  @apply border-main-black bg-main-black text-white;
}

.button_dark:hover {
  @apply border-opacity-80 bg-opacity-80;
}

.button_ghost {
  @apply border-secondary-green bg-transparent text-main-black;
}

.button_ghost:hover {
  @apply text-secondary-green;
}

.hero-button {
  max-width: 240px;
  @apply w-full py-3.5;
}
