@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url("inter-400.70530e01.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url("inter-500.684a61f9.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url("inter-600.6b87e132.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url("inter-800.0707e5da.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Gellix;
  font-style: normal;
  font-weight: 600;
  src: url("gellix-600.9bd90304.woff2") format("woff2");
}

.button {
  text-align: center;
  border-width: 1px;
  border-radius: 9999px;
  width: -moz-fit-content;
  width: fit-content;
  padding: .625rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  .button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.button_primary {
  --tw-border-opacity: 1;
  border-color: rgb(200 255 98 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(200 255 98 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(1 1 1 / var(--tw-text-opacity));
}

.button_primary:hover {
  --tw-border-opacity: .8;
  --tw-bg-opacity: .8;
}

.button_dark {
  --tw-border-opacity: 1;
  border-color: rgb(1 1 1 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(1 1 1 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.button_dark:hover {
  --tw-border-opacity: .8;
  --tw-bg-opacity: .8;
}

.button_ghost {
  --tw-border-opacity: 1;
  border-color: rgb(157 211 65 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(1 1 1 / var(--tw-text-opacity));
  background-color: #0000;
}

.button_ghost:hover {
  --tw-text-opacity: 1;
  color: rgb(157 211 65 / var(--tw-text-opacity));
}

.hero-button {
  width: 100%;
  max-width: 240px;
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.nav {
  background: linear-gradient(#d1d1d126 0%, #6666660d 100%);
  max-height: 80px;
}

.nav.opened {
  max-height: 300px;
}

.menu {
  --tw-bg-opacity: 1;
  background-color: rgb(200 255 98 / var(--tw-bg-opacity));
  border-radius: 9999px;
  flex-direction: column;
  gap: .25rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .menu {
    display: none;
  }
}

.menu:before {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgb(1 1 1 / var(--tw-bg-opacity));
  border-radius: 9999px;
  width: 50%;
  height: .125rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 15px;
  left: .625rem;
}

.menu:after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgb(1 1 1 / var(--tw-bg-opacity));
  border-radius: 9999px;
  width: 1.25rem;
  height: .125rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  bottom: 15px;
  left: .625rem;
}

.nav.opened .menu:before {
  transform: translateY(4px)rotateZ(45deg);
}

.nav.opened .menu:after {
  transform: translateY(-4px)rotateZ(-45deg);
}

.hero--background {
  width: 100%;
}

@media (min-width: 640px) {
  .hero--background {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .hero--background {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .hero--background {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .hero--background {
    max-width: 1280px;
  }
}

.hero--background {
  z-index: -10;
  aspect-ratio: 1;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (min-width: 1024px) {
  .hero--background {
    --tw-translate-y: -60.5%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.hero--border_1 {
  aspect-ratio: 1;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-width: 2px;
  border-color: #9ca3af1a;
  border-radius: 9999px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.hero--border_2 {
  aspect-ratio: 1;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-width: 2px;
  border-color: #9ca3af1a;
  border-radius: 9999px;
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.hero--border_3 {
  aspect-ratio: 1;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-width: 2px;
  border-color: #9ca3af1a;
  border-radius: 9999px;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.hero--blur {
  filter: blur(130px);
  z-index: 10;
  aspect-ratio: 1;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(200 255 98 / var(--tw-bg-opacity));
  border-radius: 9999px;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.for-whom:after {
  content: "";
  aspect-ratio: 1275 / 558;
  background-image: url("for-whom-bg.e0631c0d.svg");
  background-size: contain;
  width: 778px;
  max-width: none;
  height: auto;
  position: absolute;
  top: -2.5rem;
  right: -20rem;
}

@media (min-width: 1024px) {
  .for-whom:after {
    width: 1275px;
    top: -7.5rem;
  }
}

.solution--card_1:after {
  content: "";
  aspect-ratio: 501 / 260;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("solutions-1.c530fa3f.svg");
  width: 501px;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
}

@media (min-width: 768px) {
  .solution--card_1:after {
    bottom: -1.25rem;
  }
}

@media (min-width: 1280px) {
  .solution--card_1:after {
    bottom: 0;
  }
}

.solution--card_2:after {
  content: "";
  aspect-ratio: 486 / 155;
  background-image: url("solutions-2.ee5dbf77.svg");
  width: 486px;
  height: auto;
  position: absolute;
  bottom: 1.25rem;
  left: 0;
}

.solution--card_3:after {
  content: "";
  aspect-ratio: 265 / 209;
  background-image: url("solutions-3.657fe7f2.svg");
  width: 265px;
  height: auto;
  position: absolute;
  bottom: .75rem;
  right: 2rem;
}

.solution--card_4:after {
  content: "";
  aspect-ratio: 545 / 207;
  background-image: url("solutions-4.b87caa29.svg");
  background-size: cover;
  width: 90%;
  max-width: 545px;
  height: auto;
  padding: 2.5rem;
  position: absolute;
  bottom: 0;
  left: 1.25rem;
}

.carousel-horizontal:before {
  content: "";
  z-index: 10;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-from-position: 20%;
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  width: 3rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1024px) {
  .carousel-horizontal:before {
    width: 9rem;
  }
}

.carousel-horizontal:after {
  content: "";
  z-index: 10;
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-from-position: 20%;
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  width: 3rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .carousel-horizontal:after {
    width: 9rem;
  }
}

.pricing-plan--shadow__recommended {
  animation: 1s ease-in-out infinite alternate shadow_pulse;
}

.pricing-plan--border__default {
  background: linear-gradient(90.05deg, #6b7280 .06%, #202224 102.47%);
}

@keyframes shadow_pulse {
  from {
    box-shadow: 0 0 10px #c8ff623d;
  }

  to {
    box-shadow: 0 0 30px #c8ff623d;
  }
}

.pricing-banner__trial {
  background-image: url("pricing-trial-bg.4ce3e3c5.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-right-4 {
  right: -1rem;
}

.-top-4 {
  top: -1rem;
}

.left-0 {
  left: 0;
}

.right-6 {
  right: 1.5rem;
}

.top-0 {
  top: 0;
}

.top-\[-1px\] {
  top: -1px;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-30 {
  margin-top: 7.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-96 {
  height: 24rem;
}

.w-5 {
  width: 1.25rem;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-3xl {
  max-width: 48rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[2\] {
  flex: 2;
}

.grow {
  flex-grow: 1;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-main-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(1 1 1 / var(--tw-divide-opacity));
}

.divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: .1;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2\.5xl {
  border-radius: 1.25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b-3\.5xl {
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-main-green {
  --tw-border-opacity: 1;
  border-color: rgb(200 255 98 / var(--tw-border-opacity));
}

.border-secondary-green {
  --tw-border-opacity: 1;
  border-color: rgb(157 211 65 / var(--tw-border-opacity));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.border-tab-active-border {
  --tw-border-opacity: 1;
  border-color: rgb(42 44 48 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.bg-main-green {
  --tw-bg-opacity: 1;
  background-color: rgb(200 255 98 / var(--tw-bg-opacity));
}

.bg-secondary-black {
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 27 / var(--tw-bg-opacity));
}

.bg-secondary-green {
  --tw-bg-opacity: 1;
  background-color: rgb(157 211 65 / var(--tw-bg-opacity));
}

.bg-tab-active {
  --tw-bg-opacity: 1;
  background-color: rgb(32 34 36 / var(--tw-bg-opacity));
}

.bg-tab-bg {
  --tw-bg-opacity: 1;
  background-color: rgb(37 39 42 / var(--tw-bg-opacity));
}

.bg-tertiary-black {
  --tw-bg-opacity: 1;
  background-color: rgb(29 30 34 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-main-black {
  --tw-gradient-from: #010101 var(--tw-gradient-from-position);
  --tw-gradient-to: #01010100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-main-green {
  --tw-gradient-from: #c8ff62 var(--tw-gradient-from-position);
  --tw-gradient-to: #c8ff6200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-secondary-green {
  --tw-gradient-from: #9dd341 var(--tw-gradient-from-position);
  --tw-gradient-to: #9dd34100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white\/10 {
  --tw-gradient-from: #ffffff1a var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-0\% {
  --tw-gradient-from-position: 0%;
}

.from-45\% {
  --tw-gradient-from-position: 45%;
}

.from-60\% {
  --tw-gradient-from-position: 60%;
}

.via-secondary-green {
  --tw-gradient-to: #9dd34100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #9dd341 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-65\% {
  --tw-gradient-via-position: 65%;
}

.to-main-black {
  --tw-gradient-to: #010101 var(--tw-gradient-to-position);
}

.to-secondary-green {
  --tw-gradient-to: #9dd341 var(--tw-gradient-to-position);
}

.to-100\% {
  --tw-gradient-to-position: 100%;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-\[1px\] {
  padding: 1px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.text-center {
  text-align: center;
}

.font-gellix {
  font-family: Gellix;
}

.text-3\.5xl {
  font-size: 2rem;
  line-height: normal;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5\.5xl {
  font-size: 3.5rem;
  line-height: normal;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[-0\.5px\] {
  letter-spacing: -.5px;
}

.tracking-\[-0\.64px\] {
  letter-spacing: -.64px;
}

.tracking-\[-0\.76px\] {
  letter-spacing: -.76px;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-main-black {
  --tw-text-opacity: 1;
  color: rgb(1 1 1 / var(--tw-text-opacity));
}

.text-main-green {
  --tw-text-opacity: 1;
  color: rgb(200 255 98 / var(--tw-text-opacity));
}

.text-secondary-green {
  --tw-text-opacity: 1;
  color: rgb(157 211 65 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-opacity-20 {
  --tw-text-opacity: .2;
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-1000 {
  transition-duration: 1s;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
  font-family: Inter, sans-serif;
}

@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.hover\:bg-secondary-green:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(157 211 65 / var(--tw-bg-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:underline-offset-8:hover {
  text-underline-offset: 8px;
}

@media (min-width: 640px) {
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .md\:p-8 {
    padding: 2rem;
  }
}

@media (min-width: 1024px) {
  .lg\:left-1\/2 {
    left: 50%;
  }

  .lg\:top-6 {
    top: 1.5rem;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-30 {
    margin-bottom: 7.5rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-64 {
    margin-top: 16rem;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-11 {
    gap: 2.75rem;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:rounded-full {
    border-radius: 9999px;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-25 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-30 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-25 {
    padding-bottom: 6.25rem;
  }

  .lg\:pb-30 {
    padding-bottom: 7.5rem;
  }

  .lg\:pb-60 {
    padding-bottom: 15rem;
  }

  .lg\:pt-25 {
    padding-top: 6.25rem;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-5\.5xl {
    font-size: 3.5rem;
    line-height: normal;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:tracking-\[-1\.1px\] {
    letter-spacing: -1.1px;
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

/*# sourceMappingURL=index.442791e9.css.map */
