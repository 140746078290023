@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("/public/fonts/inter-400.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("/public/fonts/inter-500.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("/public/fonts/inter-600.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("/public/fonts/inter-800.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Gellix";
  font-style: normal;
  font-weight: 600;
  src: url("/public/fonts/gellix-600.woff2") format("woff2");
}
