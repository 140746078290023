@import "fonts.css";
@import "button.css";
@import "nav.css";
@import "hero.css";
@import "for-whom.css";
@import "solutions.css";
@import "built-will-do.css";
@import "pricing.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
  scroll-padding-top: 120px;
}
