.nav {
  max-height: 80px;
  background: linear-gradient(
    rgba(209, 209, 209, 0.15) 0%,
    rgba(102, 102, 102, 0.05) 100%
  );
}
.nav.opened {
  max-height: 300px;
}

.menu {
  @apply relative flex h-10 w-10 flex-col gap-1 rounded-full bg-main-green lg:hidden;
}

.menu:before {
  content: "";
  top: 15px;
  @apply absolute left-2.5 flex h-0.5 w-1/2  rounded-full bg-main-black transition-all;
}
.menu:after {
  content: "";
  bottom: 15px;
  @apply absolute left-2.5 flex h-0.5 w-5 rounded-full bg-main-black transition-all;
}

.nav.opened .menu:before {
  transform: translateY(4px) rotateZ(45deg);
}
.nav.opened .menu:after {
  transform: translateY(-4px) rotateZ(-45deg);
}

/*#nav.opened .menu--dash_2 {*/
/*  transform-origin: 50% 50% 0px;*/
/*  @apply -rotate-45;*/
/*}*/
