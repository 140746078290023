.solution--card_1:after {
  content: "";
  background-image: url("/src/assets/icons/solutions-1.svg");
  width: 501px;
  height: auto;
  aspect-ratio: 501/260;
  @apply absolute bottom-0 left-1/2 -translate-x-1/2 md:-bottom-5 xl:-bottom-0;
}

.solution--card_2:after {
  content: "";
  background-image: url("/src/assets/icons/solutions-2.svg");
  width: 486px;
  aspect-ratio: 486/155;
  @apply absolute bottom-5 left-0 h-auto;
}

.solution--card_3:after {
  content: "";
  background-image: url("/src/assets/icons/solutions-3.svg");
  width: 265px;
  aspect-ratio: 265/209;
  @apply absolute bottom-3 right-8 h-auto;
}

.solution--card_4:after {
  content: "";
  background-image: url("/src/assets/icons/solutions-4.svg");
  max-width: 545px;
  width: 90%;
  aspect-ratio: 545/207;
  @apply absolute bottom-0 left-5 h-auto bg-cover p-10;
}
